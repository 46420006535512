<template>
  <div class="toplogo">
    <div class="logo-image">
      <img src="@/assets/imgs/toplogo.png" alt="">
    </div>
  </div>
    
</template>

<script>
export default {
  name: 'Logo',
  data(){
      return {}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.toplogo{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  width: 100%;
  .logo-image{
    width: 50%;
    img {
      width: 100%;
      // height: 50px;
    }
  }

}
</style>
